import { Box, Fade, Flex } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import Media from "react-media";

import { ssrMode } from "@app/constants";
import { Button, LogoLink, MenuIcon } from "@components/atoms";
import LongQuiz from "@components/organisms/Quiz/LongQuiz";
import { xLargeScreen } from "@styles/constants";

import { OverlayContext, OverlayType } from "../../../legacy";
import { IProps } from "./types";

import hamburgerImg from "images/hamburger-new.svg";
import searchImg from "images/search-new.svg";

export const MainMenuLeft: React.FC<IProps> = ({ menu, resetActiveMenu }) => {
  const menuItems = menu?.items || [];
  const router = useRouter();

  const overlayContext = useContext(OverlayContext);

  const isSearchInputOpen = overlayContext.type === OverlayType.search;

  return (
    <Box
      flex={{ base: "1 1 0px", lg: "none" }}
      width={{ lg: "8.313rem" }}
      onMouseEnter={resetActiveMenu}
    >
      <Flex
        display="flex"
        alignItems="center"
        height="100%"
        styleType="none"
        marginInlineStart={0}
      >
        <Media
          query={{ minWidth: xLargeScreen }}
          defaultMatches={ssrMode && !global.isDesktop} // SSR for Mobile
          render={() => (
            <Flex as="li" alignItems="center" gap="1rem">
              {router.pathname === "/" ? (
                <Button
                  size="sm"
                  variant="ternary"
                  onClick={() =>
                    overlayContext.toggle(OverlayType.modal, null, {
                      content: <LongQuiz />,
                    })
                  }
                >
                  Get Started
                </Button>
              ) : (
                <LogoLink />
              )}

              <Fade in={!isSearchInputOpen} unmountOnExit>
                <MenuIcon
                  dataTest="menuSearchOverlayLink"
                  onClick={() => overlayContext.toggle(OverlayType.search)}
                  svgSrc={searchImg.src}
                />
              </Fade>
            </Flex>
          )}
        />

        <Media
          query={{ maxWidth: xLargeScreen - 1 }}
          defaultMatches={ssrMode && !global.isDesktop} // SSR for Mobile
          render={() => (
            <MenuIcon
              dataTest="toggleSideMenuLink"
              onClick={() =>
                overlayContext.toggle(OverlayType.mobileMainMenu, null, {
                  data: menuItems,
                })
              }
              svgSrc={hamburgerImg.src}
            />
          )}
        />
        <Media
          query={{ maxWidth: xLargeScreen - 1 }}
          defaultMatches={ssrMode && !global.isDesktop} // SSR for Mobile
          render={() => (
            <Box my="auto">
              <MenuIcon
                dataTest="menuSearchOverlayLink"
                onClick={() => overlayContext.toggle(OverlayType.search)}
                svgSrc={searchImg.src}
              />
            </Box>
          )}
        />
      </Flex>
    </Box>
  );
};
